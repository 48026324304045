<template>
  <div class="information compare">
    <b-jumbotron
      v-if="jumbotron"
      class="page-header contained"
      :header="translate(jumbotron.header, jumbotron.headerDefault)"
    />

    <div class="compare-methods-table-wrapper">
      <b-list-group
        horizontal
        class="compare-methods-table-head-group"
        :style="{ width: tableWidth }">
        <b-list-group-item
          class="compare-methods-table-head-item"
          :style="[tableHeaderIndex > 0 ? { width: `${itemWidth}%` } : { width: `${benefitsWidth}%` }]"
          v-for="(tableHeader, tableHeaderIndex) in tableHeaders"
          :key="tableHeaderIndex">{{ tableHeader.label }}</b-list-group-item>
      </b-list-group>
      <b-list-group
        horizontal
        class="compare-methods-table-benefits-group"
        :style="{ width: tableWidth }"
        v-for="(tableItem, tableItemIndex) in tableItems"
        :key="tableItemIndex">
        <b-list-group-item
          class="compare-methods-table-benefits-label"
          :style="{ width: `${benefitsWidth}%` }">{{ tableItem.label }}</b-list-group-item>
        <b-list-group-item
          class="compare-methods-table-benefits-item"
          :style="{ width: `${itemWidth}%` }"
          v-for="(method, methodIndex) in methodsToCompare"
          :key="methodIndex">
          {{ translate(`method-${method.methodId}-${tableItem.fieldName}`, (method[tableItem.fieldName] !== 1) ? method[tableItem.fieldName] : null) }}
        </b-list-group-item>
      </b-list-group>
    </div>
    <div class="scroll-icons-wrapper">
      <font-awesome-icon :icon="['far', 'chevron-left']"/>
      <font-awesome-icon :icon="['fas', 'circle']"/>
      <font-awesome-icon :icon="['fas', 'circle']"/>
      <font-awesome-icon :icon="['far', 'hand-pointer']"/>
      <font-awesome-icon :icon="['fas', 'circle']"/>
      <font-awesome-icon :icon="['fas', 'circle']"/>
      <font-awesome-icon :icon="['far', 'chevron-right']"/>
    </div>
    <section
      class="contained action-button-container"
      v-if="actionButton">
      <b-button
        :to="actionButton.link"
        block
        variant="primary"
        class="leading">
        <font-awesome-icon :icon="actionButton.icon"/>
        <span class="button-text">{{ translate(actionButton.text, actionButton.textDefault) }}</span>
      </b-button>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { clone, divide, filter } from 'lodash';

export default {
  name: 'MethodsComparisonTable',

  data: () => ({
    jumbotron: {
      header: 'methodComparisonTitle',
      headerDefault: 'Comparison of contraception options',
    },
    actionButton: {
      text: 'comparisonButtonText',
      textDefault: 'Comparison',
      link: 'compare-select',
      icon: ['fas', 'arrow-left'],
    },
    windowWidth: window.innerWidth,
  }),

  computed: {
    ...mapGetters('method', [
      'methodsToCompare',
      'methodsToCompareByMethodId',
    ]),

    ...mapGetters('translations', [
      'translate',
    ]),

    // Note this is a flipped table so headers/items needs processing
    tableHeaders() {
      const headers = [
        {
          key: 'benefits',
          label: 'BENEFITS',
        },
      ];

      this.methodsToCompare.forEach((method) => {
        headers.push({
          key: `methodId${method.methodId}`,
          label: this.translate(`method-${method.methodId}-contentTitle`, method.contentTitle),
        });
      });

      return headers;
    },
    // remove this
    oldTableItems() {
      let items = [
        {
          benefits: this.translate('methodFailureRate', 'Typical use rate'),
          fieldName: 'failureRate',
        },
        {
          benefits: this.translate('methodUsageLength', 'Usage/effective length'),
          fieldName: 'effectiveLength',
        },
        {
          benefits: this.translate('methodUsageMenstruation', 'Effect on menstruation'),
          fieldName: 'effectOnMenstruation',
        },
        {
          benefits: this.translate('methodBodyWeight', 'Effect on body weight'),
          fieldName: 'effectOnWeight',
        },
        {
          benefits: this.translate('methodBreastfeeding', 'Use during breastfeeding'),
          fieldName: 'usableDuringBreastfeeding',
        },
        {
          benefits: this.translate('methodReturnToFertility', 'Return to fertility'),
          fieldName: 'returnToFertility',
        },
        {
          benefits: this.translate('methodSTIHIVProtection', 'STI/HIV protection'),
          fieldName: 'stiProtection',
        },
      ];
      const clonedMethodsToCompare = clone(this.methodsToCompare);
      clonedMethodsToCompare.forEach((method) => {
        items.forEach((item) => {
          const itemKey = `methodId${method.methodId}`;
          const methodField = `translated${item.fieldName}`;
          method[methodField] = this.translate(`method-${method.methodId}-${item.fieldName}`, (method[item.fieldName] !== 1) ? method[item.fieldName] : null);
          item[itemKey] = method[methodField];
        });
      });

      items = filter(items, function (item) {
        return filter(clonedMethodsToCompare, function (method) {
          return item[`methodId${method.methodId}`];
        }).length;
      });

      return items;
    },

    tableItems() {
      let items = [
        {
          label: this.translate('methodFailureRate', 'Typical use rate'),
          fieldName: 'failureRate',
        },
        {
          label: this.translate('methodUsageLength', 'Usage/effective length'),
          fieldName: 'effectiveLength',
        },
        {
          label: this.translate('methodUsageMenstruation', 'Effect on menstruation'),
          fieldName: 'effectOnMenstruation',
        },
        {
          label: this.translate('methodBodyWeight', 'Effect on body weight'),
          fieldName: 'effectOnWeight',
        },
        {
          label: this.translate('methodBreastfeeding', 'Use during breastfeeding'),
          fieldName: 'usableDuringBreastfeeding',
        },
        {
          label: this.translate('methodReturnToFertility', 'Return to fertility'),
          fieldName: 'returnToFertility',
        },
        {
          label: this.translate('methodSTIHIVProtection', 'STI/HIV protection'),
          fieldName: 'stiProtection',
        },
      ];
      const clonedMethodsToCompare = clone(this.methodsToCompare);
      clonedMethodsToCompare.forEach((method) => {
        items.forEach((item) => {
          const itemKey = `methodId${method.methodId}`;
          const methodField = `translated${item.fieldName}`;
          method[methodField] = this.translate(`method-${method.methodId}-${item.fieldName}`, (method[item.fieldName] !== 1) ? method[item.fieldName] : null);
          item[itemKey] = method[methodField];
        });
      });

      items = filter(items, function (item) {
        return filter(clonedMethodsToCompare, function (method) {
          return item[`methodId${method.methodId}`];
        }).length;
      });

      return items;
    },

    extraTableClass() {
      // Check if table needs to be scrollable (+1 because of the "labels" columns)
      const tableWidth = (this.methodsToCompare.length + 1) * 170;
      if (tableWidth > this.windowWidth) {
        return 'd-inline-block';
      }

      return '';
    },

    isMobile() {
      return window.innerWidth < 767;
    },

    tableWidth() {
      if (this.isMobile) {
        const column = 12;
        return `${this.tableHeaders.length * column}rem`;
      }
      return '100%';
    },

    benefitsWidth() {
      if (this.isMobile) {
        return 25;
      }
      return 33.3333333333;
    },

    itemWidth() {
      return this.tableHeaders ? divide((100 - this.benefitsWidth), (this.tableHeaders.length - 1)) : 0;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/base/_mixins.scss";
@import "@/assets/scss/base/_variables.scss";

.scroll-icons-wrapper {
  margin-top: 1.125rem;
  font-size: 12px;
  color: $color-grey-light;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media(tablet-desktop) {
    display: none;
  }

  .fa-circle {
    font-size: 0.231rem;
  }

  svg {
    &:first-child {
      margin-right: 0.678rem;
    }

    &:last-child {
      margin-left: 0.678rem;
    }

    &:nth-child(2) {
      margin-right: 0.257rem;
    }

    &:nth-child(5) {
      margin-right: 0.257rem;
    }

    &:nth-child(4) {
      margin-left: 0.659rem;
      margin-right: 0.659rem;
    }
  }
}

.action-button-container {
  @include media(tablet-desktop) {
    a {
      width: fit-content;
      min-width: 215px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
